import {all} from "redux-saga/effects";
import settingSagas from './Setting';
import companySagas from './Company';
import departmentSagas from './Department';
import employeeSagas from './Employee';
import personalPaperSagas from './PersonalPaper';
import paymentSagas from './Payment';
import contractSagas from './Contract';
import quotationSagas from './Quotation';
import workLocationSagas from './WorkLocation';
import customerSagas from './Customer';
import leaveTrackSagas from './LeaveTrack';
import leaveTrackHistorySagas from './LeaveTrackHistory';
import sampleSages from './Sample';
import authSaga from './Auth';
import userSagas from './User';
import roleSagas from './Role';
import jobSagas from './Job';
import mailSagas from './Mail';
import mailToUserSagas from './MailToUser';
import templateSagas from './Template';
import invoiceSagas from './Invoice';
import serialNumberSagas from './SerialNumber';
import albumSagas from './Album';
import mediaSagas from './Media';
import librarySagas from './Library';
import notificationSagas from './Notification';
import partnerSagas from './Partner';
import projectSagas from './Project';
import documentSagas from './Document';
import personalMailSagas from './PersonalMail';
import salarySettingSagas from './SalarySetting';
import salaryHistorySagas from './SalaryHistory';
import approvalSagas from './Approval';
import advanceSalarySagas from './AdvanceSalary';
import advanceFeeSagas from './AdvanceFee';

export default function* rootSaga(getState) {
  yield all([
    settingSagas(),
    companySagas(),
    departmentSagas(),
    employeeSagas(),
    personalPaperSagas(),
    paymentSagas(),
    contractSagas(),
    quotationSagas(),
    workLocationSagas(),
    customerSagas(),
    leaveTrackSagas(),
    leaveTrackHistorySagas(),
    sampleSages(),
    authSaga(),
    userSagas(),
    roleSagas(),
    jobSagas(),
    mailSagas(),
    mailToUserSagas(),
    templateSagas(),
    invoiceSagas(),
    serialNumberSagas(),
    albumSagas(),
    mediaSagas(),
    librarySagas(),
    notificationSagas(),
    partnerSagas(),
    projectSagas(),
    documentSagas(),
    personalMailSagas(),
 salarySettingSagas(),
    salaryHistorySagas(),
    approvalSagas(),
    advanceSalarySagas(),
    advanceFeeSagas(),
  ]);
}
