import {
  ADVANCEFEES_SELECT_ALL,
  ADVANCEFEES_UNSELECT_ALL,
  ADVANCEFEE_SELECTED,
  ADVANCEFEES_LOAD,
  ADVANCEFEES_LOAD_SUCCESS,
  ADVANCEFEES_LOAD_FAILED,
  ADVANCEFEE_LOAD,
  ADVANCEFEE_LOAD_SUCCESS,
  ADVANCEFEE_LOAD_FAILED,
  ADVANCEFEE_ADD,
  ADVANCEFEE_ADD_SUCCESS,
  ADVANCEFEE_ADD_FAILED,
  ADVANCEFEE_UPDATE,
  ADVANCEFEE_UPDATE_SUCCESS,
  ADVANCEFEE_UPDATE_FAILED,
  ADVANCEFEE_DELETE,
  ADVANCEFEE_DELETE_SUCCESS,
  ADVANCEFEE_DELETE_FAILED,
  ADVANCEFEE_DELETE_ALL,
  ADVANCEFEE_DELETE_ALL_SUCCESS,
  ADVANCEFEE_DELETE_ALL_FAILED,
  ADVANCEFEES_EXPORT_EXCEL,
  ADVANCEFEES_EXPORT_EXCEL_SUCCESS,
  ADVANCEFEES_EXPORT_EXCEL_FAILED
} from '../constants/ActionTypes';

export const selectAllAdvanceFees = () => {
  return {
    type: ADVANCEFEES_SELECT_ALL
  }
}

export const unselectAllAdvanceFees = () => {
  return {
    type: ADVANCEFEES_UNSELECT_ALL
  }
}

export const selectAdvanceFee = data => {
  return {
    type: ADVANCEFEE_SELECTED,
    payload: data
  }
}

export const loadAdvanceFees = (data) => {
  return {
    type: ADVANCEFEES_LOAD,
    payload: data
  };
}

export const loadAdvanceFeesSuccess = (data) => {
  return {
    type: ADVANCEFEES_LOAD_SUCCESS,
    payload: data
  };
}

export const loadAdvanceFeesFailed = () => {
  return {
    type: ADVANCEFEES_LOAD_FAILED
  };
}

export const loadAdvanceFee = () => {
  return {
    type: ADVANCEFEE_LOAD
  };
}

export const loadAdvanceFeeSuccess = (data) => {
  return {
    type: ADVANCEFEE_LOAD_SUCCESS,
    payload: data
  };
}


export const loadAdvanceFeeFailed = () => {
  return {
    type: ADVANCEFEE_LOAD_FAILED
  };
}


export const addAdvanceFee = (data) => {
  return {
    type: ADVANCEFEE_ADD,
    payload: data
  };
}


export const addAdvanceFeeSuccess = (filter) => {
  return {
    type: ADVANCEFEE_ADD_SUCCESS,
    message: 'AdvanceFee added successfully.',
    payload: filter
  };
}

export const addAdvanceFeeFailed = () => {
  return {
    type: ADVANCEFEE_ADD_FAILED
  };
}

export const updateAdvanceFee = (data) => {
  return {
    type: ADVANCEFEE_UPDATE,
    payload: data
  };
}


export const updateAdvanceFeeSuccess = (data) => {
  return {
    type: ADVANCEFEE_UPDATE_SUCCESS,
    payload: data
  };
}

export const updateAdvanceFeeFailed = (data) => {
  return {
    type: ADVANCEFEE_UPDATE_FAILED,
    payload: data
  };
}

export const deleteAdvanceFee = (data) => {
  return {
    type: ADVANCEFEE_DELETE,
    payload: data
  };
}

export const deleteAdvanceFeeSuccess = () => {
  return {
    type: ADVANCEFEE_DELETE_SUCCESS,
    message: 'AdvanceFee deleted successfully.'
  };
}

export const deleteAdvanceFeeFailed = () => {
  return {
    type: ADVANCEFEE_DELETE_FAILED
  };
}

export const deleteAllAdvanceFees = (data) => {
  return {
    type: ADVANCEFEE_DELETE_ALL,
    payload: data
  };
}

export const deleteAllAdvanceFeesSuccess = () => {
  return {
    type: ADVANCEFEE_DELETE_ALL_SUCCESS,
    message: 'AdvanceFee all deleted successfully.'
  };
}

export const deleteAllAdvanceFeesFailed = () => {
  return {
    type: ADVANCEFEE_DELETE_ALL_FAILED
  };
}

export const exportExcelAdvanceFees = (filter) => {
  return {
    type:ADVANCEFEES_EXPORT_EXCEL,
    payload: filter
  } 
}

export const exportExcelAdvanceFeesSuccess = (data) => {
  return {
    type:ADVANCEFEES_EXPORT_EXCEL_SUCCESS,
    payload: data
  };
}

export const exportExcelAdvanceFeesFailed = () => {
  return {
    type:ADVANCEFEES_EXPORT_EXCEL_FAILED
  };
}
