import { combineReducers } from "redux";
import { connectRouter } from 'connected-react-router';
import Setting from './Setting';
import Company from './Company';
import Department from './Department';
import Employee from './Employee';
import PersonalPaper from './PersonalPaper';
import WorkLocation from './WorkLocation';
import Customer from './Customer';
import LeaveTrack from './LeaveTrack';
import LeaveTrackHistory from './LeaveTrackHistory';
import Payment from './Payment';
import Contract from './Contract';
import Quotation from './Quotation';
import Mail from './Mail';
import MailToUser from './MailToUser';
import Job from './Job';
import Template from './Template';
import Auth from "./Auth";
import User from "./User";
import Role from "./Role";
import Common from './Common';
import Invoice from './Invoice';
import SerialNumber from './SerialNumber';
import Album from './Album';
import Media from './Media';
import Library from './Library';
import Notification from './Notification';
import Document from './Document';
import PersonalMail from './PersonalMail';
import SalarySetting from './SalarySetting';
import SalaryHistory from './SalaryHistory';
import Approval from './Approval';
import AdvanceSalary from './AdvanceSalary';
import AdvanceFee from './AdvanceFee';
import Sample from './Sample';

export default (history) => combineReducers({
  router: connectRouter(history),
  setting: Setting,  
  company: Company,  
  department: Department,  
  employee: Employee,  
  personalpaper: PersonalPaper,  
  workLocation: WorkLocation,  
  customer: Customer,  
  leaveTrack: LeaveTrack,  
  leaveTrackHistory: LeaveTrackHistory,  
  payment: Payment,  
  contract: Contract, 
  quotation: Quotation,  
  auth: Auth,
  user: User,
  role: Role,
  common: Common,
  mail: Mail, 
  mailToUser: MailToUser, 
  job: Job, 
  template: Template,
  invoice: Invoice,
  serialNumber: SerialNumber,
  album: Album,
  media: Media,
  library: Library,
  notification: Notification,
  document: Document,
  personalMail: PersonalMail,
salarySetting: SalarySetting,
  salaryHistory: SalaryHistory,
  approval: Approval,
  advanceSalary: AdvanceSalary,
  advanceFee: AdvanceFee,
  sample: Sample
});