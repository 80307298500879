import {
  ADVANCEFEES_SELECT_ALL,
  ADVANCEFEES_UNSELECT_ALL,
  ADVANCEFEE_SELECTED,
  ADVANCEFEES_LOAD,
  ADVANCEFEES_LOAD_SUCCESS,
  ADVANCEFEES_LOAD_FAILED,
  ADVANCEFEE_LOAD,
  ADVANCEFEE_LOAD_SUCCESS,
  ADVANCEFEE_LOAD_FAILED,
  ADVANCEFEE_ADD,
  ADVANCEFEE_ADD_SUCCESS,
  ADVANCEFEE_ADD_FAILED,
  ADVANCEFEE_UPDATE,
  ADVANCEFEE_UPDATE_SUCCESS,
  ADVANCEFEE_UPDATE_FAILED,
  ADVANCEFEE_DELETE,
  ADVANCEFEE_DELETE_SUCCESS,
  ADVANCEFEE_DELETE_FAILED,
  ADVANCEFEE_DELETE_ALL,
  ADVANCEFEE_DELETE_ALL_SUCCESS,
  ADVANCEFEE_DELETE_ALL_FAILED,
  ADVANCEFEES_EXPORT_EXCEL,
  ADVANCEFEES_EXPORT_EXCEL_SUCCESS,
  ADVANCEFEES_EXPORT_EXCEL_FAILED
} from '../constants/ActionTypes';
import { PAGE_SIZES } from '../constants/AppConstant';

const INIT_STATE = {
  paging: {
    records: [],
    offset: 0,
    limit: PAGE_SIZES,
    totalRecords: 0
  },
  selectedAdvanceFee: null,
  selectedAdvanceFeeId: null,
  message: null,
  messageType: 'info',
  filter: {
    offset: 0,
    limit: PAGE_SIZES,
    orderBy: 'codeSorted',
    descending: true,
    filterGroups: null
  },
  excelBlob: null,
  hideLoading: true
};

export default (state = INIT_STATE, action) => {
  switch (action.type) {
    case ADVANCEFEES_SELECT_ALL:
      return {
        ...state,
        paging: {
          ...state.paging,
          records: state.paging.records.map(data => {
            data.selected = true;
            return data;
          })
        }
      }
    case ADVANCEFEES_UNSELECT_ALL:
      return {
        ...state,
        paging: {
          ...state.paging,
          records: state.paging.records.map(data => {
            data.selected = false;
            return data;
          })
        }
      }
    case ADVANCEFEE_SELECTED:
      return {
        ...state,
        selectedAdvanceFee: action.payload,
        selectedAdvanceFeeId: action.payload.id
      };
    case ADVANCEFEES_LOAD:
      return {
        ...state,
        filter: action.payload ? action.payload : state.filter
      };
    case ADVANCEFEES_LOAD_SUCCESS:
      return {
        ...state,
        paging: action.payload
      };
    case ADVANCEFEES_LOAD_FAILED:
      return {
        ...state
      };
    case ADVANCEFEE_LOAD:
      return {
        ...state
      };
    case ADVANCEFEE_LOAD_SUCCESS:
      return {
        ...state
      };
    case ADVANCEFEE_LOAD_FAILED:
      return {
        ...state
      };
    case ADVANCEFEE_ADD:
      return {
        ...state,
        hideLoading: false
      };
    case ADVANCEFEE_ADD_SUCCESS:
      return {
        ...state,
        hideLoading: true,
        selectedAdvanceFee: action.payload
      };
    case ADVANCEFEE_ADD_FAILED:
      return {
        ...state,
        hideLoading: true
      };
    case ADVANCEFEE_UPDATE:
      return {
        ...state,
        hideLoading: false
      };
    case ADVANCEFEE_UPDATE_SUCCESS:
      return {
        ...state,
        hideLoading: true
      };
    case ADVANCEFEE_UPDATE_FAILED:
      return {
        ...state,
        hideLoading: true
      };
    case ADVANCEFEE_DELETE:
      return {
        ...state,
        hideLoading: false
      };
    case ADVANCEFEE_DELETE_SUCCESS:
      return {
        ...state,
        message: action.message,
        hideLoading: true
      };
    case ADVANCEFEE_DELETE_FAILED:
      return {
        ...state,
        hideLoading: true
      };
    case ADVANCEFEE_DELETE_ALL:
      return {
        ...state
      };
    case ADVANCEFEE_DELETE_ALL_SUCCESS:
      return {
        ...state,
        message: action.message
      };
    case ADVANCEFEE_DELETE_ALL_FAILED:
      return {
        ...state
      };
      case ADVANCEFEES_EXPORT_EXCEL:
        return {
          ...state,
          excelBlob: null
        };
      case ADVANCEFEES_EXPORT_EXCEL_SUCCESS:
        return {
          ...state,
          excelBlob: action.payload
        };
      case ADVANCEFEES_EXPORT_EXCEL_FAILED:
        return {
          ...state,
          excelBlob: null
        };
    default:
      return state;
  }
}
